import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";

export const OrDivider: Component<{ class?: string }> = (props) => (
  <div aria-hidden class="w-full flex items-center pointer-events-none gap-4">
    <div
      class={twMerge(
        "flex-auto bg-gradient-to-r h-[2px] from-transparent to-violet-300/20",
        props.class,
      )}
    />
    <span class="text-2xs uppercase text-slate-500 dark:text-indigo-300 flex-shrink-0">
      Or
    </span>
    <div
      class={twMerge(
        "flex-auto bg-gradient-to-r h-[2px] from-violet-300/20 to-transparent",
        props.class,
      )}
    />
  </div>
);
